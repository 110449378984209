import { Col, Container, Image, Jumbotron, Row } from 'react-bootstrap';
import React from 'react';

export default (): JSX.Element => {
	return (
		<Jumbotron fluid style={{ marginBottom: 0, textAlign: 'center' }}>
			<Container>
				<Row className='justify-content-md-center'>
					<Col lg={8} md={10} sm={12}>
						<Image src={'/logos/vector/isolated-monochrome-white.svg'}></Image>
						<br />
						<br />
					</Col>
				</Row>
				<Row>
					<Col md={12}>
						<p style={{ textAlign: 'center' }}>
							Stay up to date with all things motocross in Nelspruit, White River and surrounding
							areas in the Lowveld!
						</p>
					</Col>
				</Row>
			</Container>
		</Jumbotron>
	);
};

import React from 'react';
import { Jumbotron } from 'react-bootstrap';
import './index.scss';

function PageHeading({ title, subtitle }): JSX.Element {
	return (
		<Jumbotron className='page-heading'>
			<h1>{title}</h1>
			<p>{subtitle}</p>
		</Jumbotron>
	);
}

export default PageHeading;

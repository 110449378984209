import React from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Navbar.scss';

export default (): JSX.Element => {
	return (
		<Navbar variant={'dark'} className='bg-primary custom-navbar' expand='lg'>
			<Link to='/'>
				<img
					style={{ minWidth: 200, marginRight: 5 }}
					src={'/logos/vector/default-monochrome-white.svg'}
				/>
			</Link>
			<Navbar.Toggle aria-controls='basic-navbar-nav' />
			<Navbar.Collapse id='basic-navbar-nav'>
				<Nav className='mr-auto'>
					<Link to='/' className='nav-link'>
						Home
					</Link>
					<Link to='/gallery' className='nav-link'>
						Gallery
					</Link>
					<NavDropdown title='Tracks' id='basic-nav-dropdown'>
						<Link class='dropdown-item' to='/tracks/riverside-raceway' className='nav-link'>
							Riverside Raceway
						</Link>
					</NavDropdown>
					<Link to='/contact-us' className='nav-link'>
						Contact Us
					</Link>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
};

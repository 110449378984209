import { Col, ResponsiveEmbed, Row } from 'react-bootstrap';
import React from 'react';

export default (): JSX.Element => {
	return (
		<Row className='justify-content-md-center'>
			<Col lg={10} md={12}>
				<div style={{ height: 'auto' }}>
					<ResponsiveEmbed aspectRatio='16by9'>
						<iframe
							src='https://www.youtube.com/embed/D26hCZRlCKk'
							frameBorder='0'
							allowFullScreen
						></iframe>
					</ResponsiveEmbed>
				</div>

				{/* <ResponsiveEmbed> */}
				{/*	<Card body> */}
				{/*		*/}
				{/*	</Card> */}

				{/*	/!* <iframe *!/ */}
				{/*	/!*	src='https://youtu.be/D26hCZRlCKk' *!/ */}
				{/*	/!*	frameBorder='0' *!/ */}
				{/*	/!*	allow='autoplay; encrypted-media' *!/ */}
				{/*	/!*	allowFullScreen *!/ */}
				{/*	/!*	title='video' *!/ */}
				{/*	/!* /> *!/ */}
				{/* </ResponsiveEmbed> */}
			</Col>
		</Row>
	);
};

import React from 'react';
import { Button, Container } from 'react-bootstrap';
import Intro from '../components/intro';
import HomeCarousal from '../components/carousal/static';
import Gallery from '../components/gallery';
import TrackCard from '../components/tracks/track-card';
import Video from '../components/video';
import Map from '../components/map';
import { Link } from 'react-router-dom';

const Divider = (): JSX.Element => {
	return <div style={{ height: 120 }}></div>;
};

const H1 = ({ children }): JSX.Element => {
	return <h1 style={{ textAlign: 'center', marginBottom: 20 }}>{children}</h1>;
};

function HomePage(): JSX.Element {
	return (
		<>
			<HomeCarousal />
			<Intro />
			<Divider />
			<Container>
				<div id={'gallery'}>
					<H1>Gallery</H1>
					<Gallery limit={10} />
					<div style={{ textAlign: 'center', marginTop: 20 }}>
						<Link to={'/gallery'}>
							<Button variant={'primary'}>View All</Button>
						</Link>
					</div>
					<Divider />
				</div>

				<div id={'tracks'}>
					<H1>Track</H1>
					<TrackCard />
					<Divider />
					<Map />
					<Divider />
				</div>

				<div id={'video'}>
					<Video />
					<Divider />
				</div>
			</Container>
		</>
	);
}

export default HomePage;

import React, { useState } from 'react';
import { Alert, Button, Col, Form, Row, Collapse } from 'react-bootstrap';

export default (): JSX.Element => {
	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [message, setMessage] = useState('');

	const [successMessage, setSuccessMessage] = useState('');
	const [errorMessage, setErrorMessage] = useState('');

	const encode = (data: any): string => {
		return Object.keys(data)
			.map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
			.join('&');
	};

	const handleSubmit = (e: any): void => {
		e.preventDefault();
		setSuccessMessage('');
		setErrorMessage('');

		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({ name, email, message, 'form-name': 'contact' })
		})
			.then((res) => {
				console.warn(res);
				if (!res.ok) {
					return setErrorMessage(`Failed to send message. ${res.statusText}.`);
				}

				setName('');
				setEmail('');
				setMessage('');
				setSuccessMessage('Message sent!');
			})
			.catch((error) => {
				console.error(error);
				setErrorMessage(`Failed to send message. ${JSON.stringify(error)}`);
			});
	};

	return (
		<Form id={'contact'} data-netlify='true' name='contact' method='post' onSubmit={handleSubmit}>
			<input type='hidden' name='form-name' value='contact' />
			<Row>
				<Col sm={12} md={6}>
					<Form.Group>
						<Form.Label>Name</Form.Label>
						<Form.Control type='name' value={name} onChange={(e) => setName(e.target.value)} />
					</Form.Group>
				</Col>
				<Col sm={12} md={6}>
					<Form.Group>
						<Form.Label>Email address</Form.Label>
						<Form.Control type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
					</Form.Group>
				</Col>
				<Col>
					<Form.Group>
						<Form.Label>Message</Form.Label>
						<Form.Control
							as='textarea'
							value={message}
							onChange={(e) => setMessage(e.target.value)}
						/>
					</Form.Group>
				</Col>
			</Row>

			<Collapse in={!!successMessage || !!errorMessage}>
				<Row>
					<Col>
						{successMessage ? <Alert variant={'success'}>{successMessage}</Alert> : null}
						{errorMessage ? <Alert variant={'danger'}>{errorMessage}</Alert> : null}
					</Col>
				</Row>
			</Collapse>

			<Row>
				<Col sm={12} md={6} lg={3}>
					<Button type={'submit'} block size={'lg'} variant='primary'>
						Send
					</Button>
				</Col>
			</Row>
		</Form>
	);
};

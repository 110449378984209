import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Navbar from './components/navbar';
import Home from './pages/home';
import Contact from './pages/contact';
import Gallery from './pages/gallery';
import Footer from './components/footer';
import RiversideRacewayTrack from './pages/tracks/riverside-raceway';
import ScrollToTop from './utils/ScrollToTop';

function App(): JSX.Element {
	return (
		<div>
			<Helmet>
				<meta charSet='utf-8' />
				<title>Lowveld Motocross</title>
				<meta
					name='description'
					content='Motocross in Nelspruit, White River and surrounding areas in the Lowveld'
				/>
			</Helmet>
			<Router>
				<div id={'home'}>
					<Navbar />
					<ScrollToTop />
					<Switch>
						<Route exact path='/'>
							<Home />
						</Route>

						<Route path='/gallery'>
							<Gallery />
						</Route>
						<Route path='/contact-us'>
							<Contact />
						</Route>

						<Route path='/tracks/riverside-raceway'>
							<RiversideRacewayTrack />
						</Route>
					</Switch>

					<Footer />
				</div>
			</Router>
		</div>
	);
}

export default App;

import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';

import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }: { text: string; lat?: number; lng?: number }): JSX.Element => (
	<div>
		<Image style={{ height: 20, width: 20 }} src={'/map-icon.png'} />
	</div>
);

export default (): JSX.Element => {
	const lat = -25.4239;
	const lng = 30.9677;

	return (
		<Row>
			<Col lg={12}>
				<div style={{ height: '50vh', width: '100%' }}>
					<GoogleMapReact
						bootstrapURLKeys={{ key: 'AIzaSyB9Lc_J430sy7Vi7mUbglkZelAuWBSJtlg' }}
						defaultCenter={{
							lat,
							lng
						}}
						defaultZoom={15}
					>
						<AnyReactComponent lat={lat} lng={lng} text='My Marker' />
					</GoogleMapReact>
				</div>
			</Col>
		</Row>
	);
};

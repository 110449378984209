import React from 'react';
import { Container } from 'react-bootstrap';
import PageHeading from '../../components/page-heading';
import TrackCard from '../../components/tracks/track-card';

function RiversideRacewayTrack(): JSX.Element {
	return (
		<>
			<PageHeading title={'Riverside Raceway'} subtitle={''} />
			<Container>
				<TrackCard />
			</Container>
		</>
	);
}

export default RiversideRacewayTrack;

import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import trackPage from './analytics';

function ScrollToTop({ history }): JSX.Element {
	useEffect(() => {
		trackPage();
		const unlisten = history.listen(() => {
			window.scrollTo(0, 0);
			trackPage();
		});
		return () => {
			unlisten();
		};
	}, []);

	return <></>;
}

export default withRouter(ScrollToTop);

import React from 'react';
import { Image } from 'react-bootstrap';
import './Static.scss';

export default (): JSX.Element => {
	return (
		<div className={'home-carousel'}>
			<Image fluid src='/slider/1.jpg' alt='Lowveld Motocross' />
			<div>
				{/* <h3>Riverside Raceway</h3> */}
				{/* <p>The perfect training track. It has a little bit of everything!</p> */}
			</div>
		</div>
	);
};

import React from 'react';
import Gallery from 'react-grid-gallery';
import { Col, Row } from 'react-bootstrap';

const shuffle = (array): any[] => {
	let currentIndex = array.length;
	let randomIndex;

	// While there remain elements to shuffle...
	while (currentIndex !== 0) {
		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--;

		// And swap it with the current element.
		[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
	}

	return array;
};

export default ({ limit }: { limit?: number }): JSX.Element => {
	// TODO use http://makethumbnails.com/
	// TODO https://tinypng.com/
	let IMAGES = [
		{
			src: '/gallery/comp/cannon/IMG_4109.JPG',
			thumbnail: '/gallery/comp/cannon/IMG_4109.JPG',
			thumbnailWidth: 350,
			thumbnailHeight: 260
		},
		{
			src: '/gallery/comp/cannon/IMG_4116.JPG',
			thumbnail: '/gallery/comp/cannon/IMG_4116.JPG',
			thumbnailWidth: 350,
			thumbnailHeight: 260
		},
		{
			src: '/gallery/comp/cannon/IMG_4122.JPG',
			thumbnail: '/gallery/comp/cannon/IMG_4122.JPG',
			thumbnailWidth: 350,
			thumbnailHeight: 260
		},
		{
			src: '/gallery/comp/cannon/IMG_4134.JPG',
			thumbnail: '/gallery/comp/cannon/IMG_4134.JPG',
			thumbnailWidth: 350,
			thumbnailHeight: 260
		},
		{
			src: '/gallery/comp/cannon/IMG_4161.JPG',
			thumbnail: '/gallery/comp/cannon/IMG_4161.JPG',
			thumbnailWidth: 350,
			thumbnailHeight: 260
		},
		{
			src: '/gallery/comp/cannon/IMG_4166.JPG',
			thumbnail: '/gallery/comp/cannon/IMG_4166.JPG',
			thumbnailWidth: 350,
			thumbnailHeight: 260
		},
		{
			src: '/gallery/comp/cannon/IMG_4185.JPG',
			thumbnail: '/gallery/comp/cannon/IMG_4185.JPG',
			thumbnailWidth: 350,
			thumbnailHeight: 260
		},
		{
			src: '/gallery/comp/cannon/IMG_4202.JPG',
			thumbnail: '/gallery/comp/cannon/IMG_4202.JPG',
			thumbnailWidth: 350,
			thumbnailHeight: 260
		},
		{
			src: '/gallery/comp/cannon/IMG_4275.JPG',
			thumbnail: '/gallery/comp/cannon/IMG_4275.JPG',
			thumbnailWidth: 350,
			thumbnailHeight: 260
		},
		{
			src: '/gallery/comp/dji/DJI_0203.JPG',
			thumbnail: '/gallery/comp/dji/DJI_0203.JPG',
			thumbnailWidth: 350,
			thumbnailHeight: 260
		},
		{
			src: '/gallery/comp/dji/DJI_0312.JPG',
			thumbnail: '/gallery/comp/dji/DJI_0312.JPG',
			thumbnailWidth: 350,
			thumbnailHeight: 260
		},
		{
			src: '/gallery/comp/dji/DJI_0327.JPG',
			thumbnail: '/gallery/comp/dji/DJI_0327.JPG',
			thumbnailWidth: 350,
			thumbnailHeight: 260
		},
		{
			src: '/gallery/comp/dji/DJI_0339.JPG',
			thumbnail: '/gallery/comp/dji/DJI_0339.JPG',
			thumbnailWidth: 350,
			thumbnailHeight: 260
		},
		{
			src: '/gallery/comp/dji/DJI_0348.JPG',
			thumbnail: '/gallery/comp/dji/DJI_0348.JPG',
			thumbnailWidth: 350,
			thumbnailHeight: 260
		},
		{
			src: '/gallery/comp/dji/DJI_0353.JPG',
			thumbnail: '/gallery/comp/dji/DJI_0353.JPG',
			thumbnailWidth: 350,
			thumbnailHeight: 260
		},
		{
			src: '/gallery/comp/dji/DJI_0354.JPG',
			thumbnail: '/gallery/comp/dji/DJI_0354.JPG',
			thumbnailWidth: 350,
			thumbnailHeight: 260
		},
		{
			src: '/gallery/comp/dji/DJI_0377.JPG',
			thumbnail: '/gallery/comp/dji/DJI_0377.JPG',
			thumbnailWidth: 350,
			thumbnailHeight: 260
		},
		{
			src: '/gallery/comp/misc/2021-07-18 at 17.02.49.png',
			thumbnail: '/gallery/comp/misc/2021-07-18 at 17.02.49.png',
			thumbnailWidth: 350,
			thumbnailHeight: 260
		},
		{
			src: '/gallery/comp/misc/2021-07-18 at 17.02.57.png',
			thumbnail: '/gallery/comp/misc/2021-07-18 at 17.02.57.png',
			thumbnailWidth: 350,
			thumbnailHeight: 260
		},
		{
			src: '/gallery/comp/misc/2021-07-18 at 17.03.13.png',
			thumbnail: '/gallery/comp/misc/2021-07-18 at 17.03.13.png',
			thumbnailWidth: 350,
			thumbnailHeight: 260
		},
		{
			src: '/gallery/comp/misc/2021-07-18 at 17.03.23.png',
			thumbnail: '/gallery/comp/misc/2021-07-18 at 17.03.23.png',
			thumbnailWidth: 350,
			thumbnailHeight: 260
		},
		{
			src: '/gallery/comp/misc/217827694_286377136619043_6091914264149474987_n.jpg',
			thumbnail: '/gallery/comp/misc/217827694_286377136619043_6091914264149474987_n.jpg',
			thumbnailWidth: 350,
			thumbnailHeight: 260
		},
		{
			src: '/gallery/comp/phone/3FFA3AE1D24BB905220A9EE81FF2AF99.JPG',
			thumbnail: '/gallery/comp/phone/3FFA3AE1D24BB905220A9EE81FF2AF99.JPG',
			thumbnailWidth: 350,
			thumbnailHeight: 260
		},
		{
			src: '/gallery/comp/phone/IMG_5191.JPG',
			thumbnail: '/gallery/comp/phone/IMG_5191.JPG',
			thumbnailWidth: 350,
			thumbnailHeight: 260
		},
		{
			src: '/gallery/comp/phone/IMG_5197.jpg',
			thumbnail: '/gallery/comp/phone/IMG_5197.jpg',
			thumbnailWidth: 350,
			thumbnailHeight: 260
		},
		{
			src: '/gallery/comp/phone/IMG_5198.JPG',
			thumbnail: '/gallery/comp/phone/IMG_5198.JPG',
			thumbnailWidth: 350,
			thumbnailHeight: 260
		},
		{
			src: '/gallery/comp/phone/IMG_5203.JPG',
			thumbnail: '/gallery/comp/phone/IMG_5203.JPG',
			thumbnailWidth: 350,
			thumbnailHeight: 260
		},
		{
			src: '/gallery/comp/phone/IMG_5204.JPG',
			thumbnail: '/gallery/comp/phone/IMG_5204.JPG',
			thumbnailWidth: 350,
			thumbnailHeight: 260
		},
		{
			src: '/gallery/comp/phone/IMG_5214.JPG',
			thumbnail: '/gallery/comp/phone/IMG_5214.JPG',
			thumbnailWidth: 350,
			thumbnailHeight: 260
		},
		{
			src: '/gallery/comp/phone/IMG_6826.jpg',
			thumbnail: '/gallery/comp/phone/IMG_6826.jpg',
			thumbnailWidth: 350,
			thumbnailHeight: 260
		},
		{
			src: '/gallery/comp/phone/PHOTO-2021-05-30-19-24-43.jpg',
			thumbnail: '/gallery/comp/phone/PHOTO-2021-05-30-19-24-43.jpg',
			thumbnailWidth: 350,
			thumbnailHeight: 260
		}
	];

	if (limit && limit < IMAGES.length) {
		IMAGES = IMAGES.splice(IMAGES.length - limit, limit);
	}

	IMAGES = shuffle(IMAGES);

	return (
		<>
			<Row>
				<Col sm={12}>
					<Gallery enableImageSelection={false} backdropClosesModal images={IMAGES} />
				</Col>
			</Row>
		</>
	);
};

const trackPage = (): void => {
	if (location.hostname === 'localhost') {
		return;
	}

	// eslint-disable-next-line no-prototype-builtins
	if (!window.hasOwnProperty('gtag')) {
		return;
	}

	if ('gtag' in window) {
		const gtag = (window as any).gtag;

		gtag('config', 'G-2E8V8FKRNQ', {
			page_path: window.location.pathname
		});
	}
};

export default trackPage;

import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Contact from '../components/contact';
import PageHeading from '../components/page-heading';

function ContactPage(): JSX.Element {
	return (
		<>
			<Helmet>
				<meta charSet='utf-8' />
				<title>Lowveld Motocross - Contact Us</title>
				<meta name='description' content='Lowveld Motocross contact page' />
			</Helmet>
			<PageHeading
				title={'Contact us'}
				subtitle={
					'This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.'
				}
			/>
			<Container>
				<Contact />
			</Container>
		</>
	);
}

export default ContactPage;

import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap';
import Gallery from '../components/gallery';
import PageHeading from '../components/page-heading';
import Video from '../components/video';

function GalleryPage(): JSX.Element {
	return (
		<>
			<Helmet>
				<meta charSet='utf-8' />
				<title>Lowveld Motocross - Gallery</title>
				<meta name='description' content='Photos of motocross in Nelspruit' />
			</Helmet>
			<PageHeading
				title={'Gallery'}
				subtitle={`The feeling when you ride is like no other feeling. You feel free from the world, you feel unstoppable, riding is a passion, it's a joy, it's a stress reliever, it's a way of life.`}
			/>
			<Container>
				<Video />
				<br />
				<br />
				<Gallery />
			</Container>
		</>
	);
}

export default GalleryPage;

import { Button, Col, Container, Image, Jumbotron, Row } from 'react-bootstrap';
import React from 'react';
import { Link } from 'react-router-dom';

export default (): JSX.Element => {
	return (
		<Jumbotron style={{ marginBottom: 0, marginTop: 100 }}>
			<Container>
				<Row>
					<Col lg={4} md={6} sm={12}>
						<Image src={'/logos/vector/default-monochrome-white.svg'}></Image>
						<br />
						<br />
					</Col>
				</Row>
				<Row>
					<Col lg={4} md={6} sm={12}>
						<p>“To live is the rarest thing in the world; most people just exist.” -Oscar Wilde</p>

						<p>
							<Link to={'/contact-us'}>
								<Button variant='primary'>Contact us</Button>
							</Link>
						</p>
					</Col>
					<Col lg={8} md={6} sm={12} style={{ textAlign: 'right' }}>
						<p>
							<Link to={'/'}>Home</Link>
						</p>
						<p>
							<Link to={'/gallery'}>Gallery</Link>
						</p>
						<p>
							<Link to={'/tracks/riverside-raceway'}>Tracks</Link>
						</p>
					</Col>
				</Row>
			</Container>
		</Jumbotron>
	);
};

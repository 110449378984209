import { Button, Card, Col, ListGroup, Row } from 'react-bootstrap';
import React from 'react';

export default (): JSX.Element => {
	return (
		<Row className='justify-content-md-center'>
			<Col lg={8} md={12}>
				<Card style={{ textAlign: 'center' }}>
					<Card.Img variant='top' src='/tracks/riverside.jpg' />
					<Card.Body>
						<Card.Title>Riverside Raceway</Card.Title>
						<h1>R150 per bike</h1>

						<p>
							The perfect training track. Berm corners, sandy corners, small jumps, big jumps. It
							has a little bit of everything!
						</p>

						<ListGroup as='ul'>
							<ListGroup.Item as='li'>
								<b>Monday - Friday</b>
								<br />
								09:00 - 17:00
							</ListGroup.Item>
							<ListGroup.Item as='li'>
								<b>Saturday</b>
								<br />
								09:00 - 15:30
							</ListGroup.Item>
							<ListGroup.Item as='li'>
								<b>Sunday</b>
								<br />
								Closed
							</ListGroup.Item>
						</ListGroup>
						<Button
							variant='primary'
							href={'https://goo.gl/maps/P1kUuWq7yEnirg4F7'}
							target={'_blank'}
						>
							Get Directions
						</Button>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	);
};
